import {useEffect} from "react";
import {loadModules} from "esri-loader";

const useCreateScene = (sceneRef) => {
    useEffect(() => {
        let view;
        const initializeScene = async (sceneRef) => {
            const modules = [
                "esri/Map", "esri/views/SceneView", "esri/layers/WFSLayer",
                "esri/layers/ogc/wfsUtils", "esri/widgets/Search",
                "esri/widgets/BasemapToggle", "esri/widgets/LayerList",
                "esri/layers/WMSLayer", "esri/widgets/Feature", "esri/core/promiseUtils",
                "esri/PopupTemplate",
            ];

            let wfsCapabilities;

            const [Map, SceneView, WFSLayer,
                wfsUtils, Search,
                BasemapToggle, LayerList,
                WMSLayer, Feature, promiseUtils,
                PopupTemplate,
            ] = await loadModules(modules);
            const map = new Map({
                basemap: "hybrid"
            });
            view = new SceneView({
                map: map,
                zoom: 6,
                container: sceneRef.current,
                center: [109, 6],
                popup: {
                    defaultPopupTemplateEnabled: true // popup will be enabled on the wfslayer
                },
                spatialReference: {
                    wkid: 102100
                },
                heading: 30,
                tilt: 60
            })

            function getCapabilities() {
                // listArea.innerHTML = ""; // clear the list of featuretypes when a new GetCapabilites request is execute
                // loader.hidden = false;
                let url;
                // url = "https://geo.zedpro.me/OSM/ows";
                if (url) {
                    // call get capabilities request on the WFS endpoint
                    wfsUtils.getCapabilities(url).then((capabilities) => {
                        // warning.open = false;
                        wfsCapabilities = capabilities;
                        // create list of featuretypes from the capabilities result
                        // createLayerList(wfsCapabilities.featureTypes);
                        wfsCapabilities.featureTypes.forEach((featureLayer) => {
                            wfsUtils.getWFSLayerInfo(wfsCapabilities, featureLayer.typeName).then((wfsLayerInfo) => {
                                // create a WFSLayer from the layer info
                                const layer = WFSLayer.fromWFSLayerInfo(wfsLayerInfo);
                                layer.opacity = 0.35;
                                layer.outFields = ["*"];
                                layer.visible = false;
                                map.add(layer);

                                // const template = {
                                //     title: "{name}",
                                //     description: "{*}"
                                // };
                                //
                                // layer.popupTemplate = template;
                                //
                                // console.log(layer.popupTemplate)

                                // layer.when(() => {
                                //     // zoom to the layer's extent once it loads
                                //     view.goTo(layer.fullExtent);
                                // });
                            });
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }
            }

            getCapabilities();

            const statesLayer = new WFSLayer({
                url: "https://geo.zedpro.me/ArcGIS/ows", // url to your WFS endpoint
                name: "ArcGIS:MalaysianStates" // name of the FeatureType
            });
            statesLayer.opacity = 0.35;
            // statesLayer.visible = false;
            // console.log(statesLayer.fields, statesLayer.popupTemplate)

            map.add(statesLayer); // add the layer to the map


            const template = {
                // NAME and COUNTY are fields in the service containing the Census Tract (NAME) and county of the feature
                title: "{name}",
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "Alt Name",
                                label: "Alt Name"
                            },
                            {
                                fieldName: "Country",
                                label: "Country"
                            },
                            {
                                fieldName: "Type",
                                label: "Type"
                            },
                            {
                                fieldName: "Population",
                                label: "Population"
                            }
                        ]
                    }
                ]
            };

            statesLayer.popupTemplate = template;

            const districtsLayer = new WFSLayer({
                url: "https://geo.zedpro.me/ArcGIS/ows", // url to your WFS endpoint
                name: "ArcGIS:District" // name of the FeatureType
            });
            districtsLayer.opacity = 0.35
            districtsLayer.visible = false;
            map.add(districtsLayer); // add the layer to the map


            // const waterLayer = new WFSLayer({
            //     url: "https://geo.zedpro.me/OSM/ows", // url to your WFS endpoint
            //     name: "OSM:MSB Water_A" // name of the FeatureType
            // });
            // waterLayer.opacity = 1;
            // // waterLayer.visible = false;
            // waterLayer.minScale = 100000;
            // map.add(waterLayer); // add the layer to the map
            //
            // const roadLayer = new WFSLayer({
            //     url: "https://geo.zedpro.me/OSM/ows", // url to your WFS endpoint
            //     name: "OSM:MSB Roads" // name of the FeatureType
            // });
            // roadLayer.opacity = 1;
            // // roadLayer.visible = false;
            // roadLayer.minScale = 1000000
            //
            // map.add(roadLayer); // add the layer to the map

            // Add WMS Layer with opacity reduced
            // const webMapLayer = new WMSLayer({
            //     url: "https://geo.zedpro.me/ows",
            //     title: "Opensource Data",
            //     sublayers: [
            //         {
            //             name: "nurc:Arc_Sample",
            //             title: "Global Rainfall",
            //             visible: false,
            //         }
            //     ]
            // });
            // webMapLayer.opacity = 0.2
            // // webMapLayer.visible = false
            // map.add(webMapLayer); // add the layer to the map


            // const OSMLayer = new WMSLayer({
            //     url: "https://geo.zedpro.me/OSM/ows",
            //     title: "OpenStreetMap",
            //     sublayers: [
            //         {
            //             name: "OSM:MSB Roads",
            //             title: "Roads",
            //             visible: false,
            //         }
            //     ]
            // });
            // OSMLayer.opacity = 0.2
            // // webMapLayer.visible = false
            // map.add(OSMLayer); // add the layer to the map


            // const roadsLayer = new WFSLayer({
            //     url: "https://geo.zedpro.me/OSM/ows", // url to your WFS endpoint
            //     name: "OSM:MSB Roads" // name of the FeatureType
            // });
            // roadsLayer.opacity = 1
            //
            // // console.log(statesLayer.fields, statesLayer.popupTemplate)
            //
            // map.add(roadsLayer); // add the layer to the map


            const featureGraphic = {
                popupTemplate: {
                    content: "Mouse over features to show details..."
                }
            };

            const feature = new Feature({
                graphic: featureGraphic,
                map: view.map,
                spatialReference: view.spatialReference
            });

            view.ui.add(feature, "bottom-left");


            view.whenLayerView(statesLayer).then((layerView) => {
                let highlight;
                let objectId;

                const debouncedUpdate = promiseUtils.debounce(async (event) => {
                    // Perform a hitTest on the View
                    const hitTest = await view.hitTest(event);
                    // console.log(hitTest)
                    // Make sure graphic has a popupTemplate
                    const results = hitTest.results.filter((result) => {
                        // console.log(result.graphic.layer.popupTemplate)
                        return result.graphic.layer.popupTemplate;
                    });
                    // console.log(results)

                    const result = results[0];
                    const newObjectId = result?.graphic.attributes[statesLayer.objectIdField];

                    if (!newObjectId) {
                        highlight?.remove();
                        objectId = feature.graphic = featureGraphic;
                    } else if (objectId !== newObjectId) {
                        highlight?.remove();
                        objectId = newObjectId;
                        feature.graphic = result.graphic;
                        highlight = layerView.highlight(result.graphic);
                    }
                });

                // Listen for the pointer-move event on the View
                view.on("pointer-move", (event) => {
                    debouncedUpdate(event).catch((err) => {
                        if (!promiseUtils.isAbortError(err)) {
                            throw err;
                        }
                    });
                });
            });

            const searchWidget = new Search({
                view: view
            });

            const basemapToggle = new BasemapToggle({
                view: view,
                nextBasemap: "topo-vector"
            });

            view.ui.add(searchWidget, {
                position: "top-right",
                index: 2
            });

            view.ui.add(basemapToggle,"bottom-right");


            view.when(() => {
                const layerList = new LayerList({
                    view: view,
                    label: "test",
                    listItemCreatedFunction: defineActions,
                });

                async function defineActions(event) {
                    // The event object contains an item property.
                    // is a ListItem referencing the associated layer
                    // and other properties. You can control the visibility of the
                    // item, its title, and actions using this object.

                    const item = event.item;

                    // console.log(item.layer.when)

                    if(item.layer.when){
                        await item.layer.when();
                    }

                    if (item.title) {
                        // An array of objects defining actions to place in the LayerList.
                        // By making this array two-dimensional, you can separate similar
                        // actions into separate groups with a breaking line.

                        item.actionsSections = [
                            [
                                {
                                    title: "Go to full extent",
                                    className: "esri-icon-zoom-out-fixed",
                                    id: "full-extent"
                                },
                                {
                                    title: "Layer information",
                                    className: "esri-icon-description",
                                    id: "information"
                                }
                            ],
                            [
                                {
                                    title: "Increase opacity",
                                    className: "esri-icon-up",
                                    id: "increase-opacity"
                                },
                                {
                                    title: "Decrease opacity",
                                    className: "esri-icon-down",
                                    id: "decrease-opacity"
                                }
                            ]
                        ];
                    }
                }


                layerList.on("trigger-action", (event) => {
                    const visibleLayer = event.item.layer
                    console.log(visibleLayer)

                    // Capture the action id.
                    const id = event.action.id;

                    if (id === "full-extent") {
                        // If the full-extent action is triggered then navigate
                        // to the full extent of the visible layer.
                        view.goTo(visibleLayer.fullExtent);
                    } else if (id === "information") {
                        // If the information action is triggered, then
                        // open the item details page of the service layer.
                        window.open(visibleLayer.url);
                    } else if (id === "increase-opacity") {
                        // If the increase-opacity action is triggered, then
                        // increase the opacity of the GroupLayer by 0.25.

                        if (visibleLayer.opacity < 1) {
                            visibleLayer.opacity += 0.1;
                        }
                    } else if (id === "decrease-opacity") {
                        // If the decrease-opacity action is triggered, then
                        // decrease the opacity of the GroupLayer by 0.25.

                        if (visibleLayer.opacity > 0) {
                            visibleLayer.opacity -= 0.1;
                        }
                    }
                });

                // Add widget to the top right corner of the view
                view.ui.add(layerList, "top-right");
            });

            // view.map.layers.forEach(layer => {
            //     console.log(layer)
            // })
            //
            // console.log(view.map.layers, view.map.editableLayers)


            view.on('click', async (event) => {
                const response = await view.hitTest(event)
                let graphic;
                let attributes;

                if (response.results.length) {
                    graphic = response.results[0].graphic;
                    attributes = graphic.attributes;
                    console.log(graphic, attributes);
                }

                const details = {
                    screenPoint: event.screenPoint,
                    coordinates: {latitude: event.mapPoint.latitude, longitude: event.mapPoint.longitude},
                    extent: graphic ? graphic.geometry.extent : null,
                    rings: graphic ? graphic.geometry.rings : null,
                    spacialReference: event.mapPoint.spatialReference.wkid,
                    paths: graphic ? graphic.geometry.paths : null,
                }
                console.log(details)
                view.goTo(details.extent)
            })

        };

        initializeScene(sceneRef);

        return () => view?.destroy();
    }, [sceneRef])
}

export default useCreateScene;