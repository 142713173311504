import React, {useRef} from 'react';
import useCreateScene from "../hooks/useCreateScene";
// import '../App.css';

const Scene = () => {
    const sceneRef = useRef(null);
    useCreateScene(sceneRef);
    return (
        <div
            className="map-view" ref={sceneRef}
        />
    );
}

export default Scene;