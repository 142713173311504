import './App.css';
// import Map from "./components/Map";
import Scene from "./components/Scene";

function App() {
  return (
    <div className="App">
        {/*<Map/>*/}
        <Scene />
    </div>
  );
}

export default App;
